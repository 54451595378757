/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.icon-effect.danger::before {
  background-color: rgba(179, 49, 10, 0.2)
}

.timeline::after {
  background: #878a99;
}

.user-name-text {
  color: var(--vz-gray-700);
}

@media (max-width: 991.98px) {
  .timeline::after {
    left: 35px;
  }

  .timeline-item {
    padding-left: 80px;
  }

  .timeline-item.left .icon, .timeline-item.right .icon {
    left: 15px;
  }
}